'use client';

import { PaymentProductModel } from '@project-adonis/validators';
import dayjs from 'dayjs';
import Link from 'next/link';
import { ComponentType, useMemo, useState } from 'react';
import Countdown from '../Countdown/Countdown';
import SalesButton from './SalesButton/SalesButton';
import * as routes from '@/src/shared/constants/routes';
import { usePaymentProducts } from '@/src/shared/hooks/queries/usePaymentProducts';
import useIsHydrated from '@/src/shared/hooks/useIsHydrated';
import { useIsPremium } from '@/src/shared/hooks/useIsPremium';
import useSaleEndDate from '@/src/shared/hooks/useSaleEndDate';
interface Props {
  containerClassName?: string;
}
const SalesCountdown: ComponentType<Props> = ({
  containerClassName = ''
}) => {
  const isHydrated = useIsHydrated();
  const isPremium = useIsPremium();
  const storageEndTimestamp = useSaleEndDate();
  const endTimestamp = storageEndTimestamp?.toDate();
  const [isFinished, setIsFinished] = useState(false);
  const {
    data: paymentProducts = [],
    isLoading
  } = usePaymentProducts();
  const maxDiscount = useMemo(() => paymentProducts.reduce((max: number, {
    priceInCents,
    retailPriceInCents
  }: PaymentProductModel) => Math.max(Math.round(100 - 100 * priceInCents / retailPriceInCents), max), 0), [paymentProducts]);
  if (!isHydrated || isLoading || isPremium || isFinished || !endTimestamp || dayjs(endTimestamp).isBefore(new Date())) {
    return null;
  }
  return <Link href={routes.PRICING} className="flex w-full bg-[#340639]" data-sentry-element="Link" data-sentry-component="SalesCountdown" data-sentry-source-file="SalesCountdown.tsx">
      <div className={`w-full mx-auto grid grid-cols-2 md:grid-cols-[170px_1fr] lg:grid-cols-[170px_1fr_170px] items-center justify-between py-1 px-4 md:px-6 max-w-screen-lg ${containerClassName}`}>
        <Countdown endTimeStamp={endTimestamp} onFinish={() => setIsFinished(true)} data-sentry-element="Countdown" data-sentry-source-file="SalesCountdown.tsx" />
        <div className="flex justify-end lg:justify-center">
          <SalesButton maxDiscount={maxDiscount} data-sentry-element="SalesButton" data-sentry-source-file="SalesCountdown.tsx" />
        </div>
        <div></div>
      </div>
    </Link>;
};
export default SalesCountdown;