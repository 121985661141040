import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { fetcher } from '@/src/shared/config/queryClient';

interface AdvanceInfiniteQueryParams<DataType>
  extends Omit<
    UseInfiniteQueryOptions<DataType>,
    'queryFn' | 'getNextPageParam' | 'initialPageParam'
  > {
  limit: number;
  search?: string;
  initialPageParam?: number;
}

export interface AdvanceInfiniteQueryHookParams<DataType>
  extends Omit<
    AdvanceInfiniteQueryParams<DataType>,
    'queryKey' | 'initialPageParam'
  > {
  limit: number;
  search?: string;
  initialPageParam?: number;
}

export type AdvanceInfiniteQueryResult<DataType> = UseInfiniteQueryResult<
  DataType,
  Error
> & {
  flatData: DataType;
  isEmpty: boolean;
};

export const useAdvanceInfiniteQuery = <DataType>({
  limit = 10,
  search = '',
  queryKey,
  initialPageParam = 0,
  ...config
}: AdvanceInfiniteQueryParams<DataType>): AdvanceInfiniteQueryResult<DataType> => {
  const { data, isLoading, ...rest } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => {
      const url = queryKey[0] as string;
      const searchParams = new URLSearchParams();

      // For first page we fetch limit - 1 items, for the rest we fetch limit items.
      // This is because in grid we show the loading skeleton on the same line as the last records.
      // This is specifically for UI purposes.
      searchParams.append('limit', String(pageParam === 0 ? limit - 1 : limit));
      searchParams.append('offset', String(pageParam));
      searchParams.append('search', search);

      return fetcher<DataType>(`${url}?${searchParams.toString()}`);
    },
    getNextPageParam: (lastPage, pages) => {
      // This logic is adjusted for what we talked about in queryFn.
      const firstPage = pages[0];
      const lastPageIsFirstPage = lastPage === firstPage;
      const adjustedLimit = lastPageIsFirstPage ? limit - 1 : limit;
      return (lastPage as []).length === adjustedLimit
        ? pages.flat().length
        : null;
    },
    initialPageParam,
    ...config,
  });

  const flatData = useMemo(
    () => (data as InfiniteData<DataType>)?.pages.flat() ?? [],
    [data],
  );
  const isEmpty = !flatData.length && !isLoading;

  return {
    ...rest,
    isLoading,
    isEmpty,
    flatData: flatData,
  } as AdvanceInfiniteQueryResult<DataType>;
};
