'use client';

import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useUiStore } from '@/src/shared/hooks/stores/useUiStore';

const useSaleEndDate = (): Dayjs | null => {
  const [saleEndDate, setSaleEndDate] = useState<Dayjs | null>(null);
  const { saleExpirationDate, setSaleExpirationDate } = useUiStore();

  useEffect(() => {
    const finalDate =
      saleExpirationDate && dayjs(saleExpirationDate).isBefore(dayjs())
        ? dayjs().add(1, 'hour')
        : (saleExpirationDate && dayjs(saleExpirationDate)) ||
          dayjs().add(1, 'hour');

    setSaleExpirationDate(finalDate.toISOString());

    setSaleEndDate(finalDate);
  }, [saleExpirationDate, setSaleExpirationDate]);

  if (!saleEndDate) {
    return null;
  }

  return saleEndDate;
};

export default useSaleEndDate;
