'use client';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslations } from 'next-intl';
import { ComponentType, useCallback, useEffect, useState } from 'react';
import './Countdown.scss';
dayjs.extend(duration);
interface Props {
  endTimeStamp: Date;
  onFinish: () => void;
}
const Countdown: ComponentType<Props> = ({
  endTimeStamp,
  onFinish
}: Props) => {
  const t = useTranslations();
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState<number | null>(null);
  const calculateTimeLeft = useCallback((): number => {
    const now = dayjs();
    const end = dayjs(endTimeStamp);
    return dayjs.duration(end.diff(now)).asSeconds();
  }, [endTimeStamp]);
  useEffect(() => {
    setTimeLeftInSeconds(calculateTimeLeft());
    const interval = setInterval(() => {
      setTimeLeftInSeconds(time => {
        if (time !== null) {
          if (time - 1 <= 0) {
            clearInterval(interval);
            onFinish();
            return 0;
          }
          return time - 1;
        }
        return 0;
      });
    }, 1000);
    return (): void => {
      clearInterval(interval);
    };
  }, [calculateTimeLeft, endTimeStamp, onFinish]);
  if (timeLeftInSeconds === null) {
    return null;
  }
  const hours = Math.floor(timeLeftInSeconds / 3600).toString().padStart(2, '0');
  const minutes = Math.floor(timeLeftInSeconds % 3600 / 60).toString().padStart(2, '0');
  const seconds = Math.floor(timeLeftInSeconds % 60).toString().padStart(2, '0');
  return <div className="Countdown" data-sentry-component="Countdown" data-sentry-source-file="Countdown.tsx">
      <div className="Countdown__unit">
        <span className="Countdown__unit-value">{hours}</span>
        <span className="Countdown__unit-label">
          {t('salesCountdown.countdown.hours')}
        </span>
      </div>
      <div>:</div>
      <div className="Countdown__unit">
        <span className="Countdown__unit-value">{minutes}</span>
        <span className="Countdown__unit-label">
          {t('salesCountdown.countdown.minutes')}
        </span>
      </div>
      <div>:</div>
      <div className="Countdown__unit">
        <span className="Countdown__unit-value">{seconds}</span>
        <span className="Countdown__unit-label">
          {t('salesCountdown.countdown.seconds')}
        </span>
      </div>
    </div>;
};
export default Countdown;