import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useRouter } from 'nextjs-toploader/app';
import * as routes from '@/src/shared/constants/routes';
import { useCreateConversationMutation } from '@/src/shared/hooks/queries/useCreateConversationMutation';
import useIsAuthenticated from '@/src/shared/hooks/useIsAuthenticated';
import { convertStringToSlug } from '@/src/shared/utilities/helpers/client/helpers';
export const useStartConversationMutation = (): UseMutationResult<void, Error, {
  agentId: number;
  agentName?: string;
}> => {
  const {
    mutateAsync
  } = useCreateConversationMutation();
  const isAuthenticated = useIsAuthenticated();
  const router = useRouter();
  return useMutation({
    mutationFn: async ({
      agentId,
      agentName
    }) => {
      let id: number | undefined;
      let slug: string | undefined;
      if (!isAuthenticated) {
        id = agentId;
        slug = agentName ?? 'character';
      } else {
        const conversation = await mutateAsync({
          agentId
        });
        id = conversation.id;
        slug = convertStringToSlug(conversation.agent.name);
      }
      router.push(routes.CHAT_ID.replace(':id', id.toString()).replace(':slug', convertStringToSlug(slug)));
    }
  });
};