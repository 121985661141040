import { Card, Button } from 'antd';
import Link from 'next/link';
import { JSX, ComponentType } from 'react';
import { CtaType } from '@/src/shared/types/cta-type';
interface Props {
  icon: JSX.Element;
  text: string | JSX.Element;
  cta: CtaType;
  className?: string;
  isLoading?: boolean;
}
const CtaCard: ComponentType<Props> = ({
  icon,
  text,
  cta,
  className = '',
  isLoading = false
}) => <Card hoverable bordered={false} className={`!bg-primary-500 w-full !rounded-2xl sm:!rounded-3xl flex items-center justify-center aspect-character-image text-center ${className}`} classNames={{
  body: '!p-4 sm:!p-6'
}} data-sentry-element="Card" data-sentry-component="CtaCard" data-sentry-source-file="CtaCard.tsx">
    <div className="flex flex-col gap-2 sm:gap-3 items-center justify-center">
      <div className="text-3xl sm:text-4xl">{icon}</div>

      <p className="text-md sm:text-xl font-bold">{text}</p>

      <Link href={cta.action ?? ''} scroll={false} onClick={cta.onClick} data-sentry-element="Link" data-sentry-source-file="CtaCard.tsx">
        <Button className="!bg-black max-sm:!h-auto max-sm:!px-3 max-sm:!py-1 max-sm:!text-sm" type="primary" size="large" icon={cta.icon} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="CtaCard.tsx">
          {cta.text}
        </Button>
      </Link>
    </div>
  </Card>;
export default CtaCard;