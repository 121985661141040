'use client';

import { ConversationModel } from '@project-adonis/validators';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import getQueryClient, {
  mutationFetcherCreator,
} from '@/src/shared/config/queryClient';

export const useCreateConversationMutation = (): UseMutationResult<
  ConversationModel,
  Error,
  { agentId: number }
> => {
  return useMutation({
    mutationFn: mutationFetcherCreator('/api/conversations', 'POST'),
    onSuccess: async () => {
      await getQueryClient().invalidateQueries({
        queryKey: ['/api/conversations'],
        exact: false,
      });
    },
  });
};
