import { AgentModel } from '@project-adonis/validators';
import {
  AdvanceInfiniteQueryHookParams,
  AdvanceInfiniteQueryResult,
  useAdvanceInfiniteQuery,
} from '@/src/shared/hooks/queries/useAdvanceInfiniteQuery';

export const useMyCharacters = ({
  limit = 10,
  search = '',
  ...config
}: AdvanceInfiniteQueryHookParams<AgentModel[]>): AdvanceInfiniteQueryResult<
  AgentModel[]
> => {
  return useAdvanceInfiniteQuery({
    queryKey: ['/api/agents/user', limit, search],
    limit,
    search,
    ...config,
  });
};
