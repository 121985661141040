'use client';

const useIsTouchDevice = (): boolean => {
  if (typeof window !== 'undefined') {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
  return false;
};

export default useIsTouchDevice;
