import { useEffect, useState } from 'react';
const useIsHydrated = (): boolean => {
  const [isHydrated, setIsHydrated] = useState(false);
  useEffect(() => {
    setIsHydrated(true);
    return (): void => {
      setIsHydrated(false);
    };
  }, []);
  return isHydrated;
};
export default useIsHydrated;