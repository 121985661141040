'use client';

import { AgentModel } from '@project-adonis/validators';
import { Button, Skeleton } from 'antd';
import dynamic from 'next/dynamic';
import React, { ComponentType, useCallback, useRef, useState } from 'react';
import HttpError from '@/src/shared/api/http-error';
import ChatIcon from '@/src/shared/components/Icons/Chat';
import Image from '@/src/shared/components/Image/Image';
import { useStartConversationMutation } from '@/src/shared/hooks/queries/useStartConversationMutation';
import { useNotificationStore } from '@/src/shared/hooks/stores/useNotificationStore';
import useIsTouchDevice from '@/src/shared/hooks/useIsTouchDevice';
const VideoPreview = dynamic(() => import('@/src/shared/components/CharacterCard/VideoPreview/VideoPreview'), {
  ssr: false
});
interface Props {
  character: AgentModel;
  showVideoPreview?: boolean;
  rootClassName?: string;
}
const CharacterCard: ComponentType<Props> = ({
  character,
  showVideoPreview = false,
  rootClassName = ''
}: Props) => {
  const {
    id,
    name,
    description,
    avatar,
    videoPreview
  } = character;
  const {
    notificationApi
  } = useNotificationStore();
  const {
    mutateAsync,
    isPending
  } = useStartConversationMutation();
  const isTouchDevice = useIsTouchDevice();
  const [isImageLoading, setIsImageLoading] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const handleOpenConversation = useCallback(async () => {
    if (!id) return;
    try {
      await mutateAsync({
        agentId: id,
        agentName: name
      });
    } catch (e) {
      if (e instanceof HttpError) {
        notificationApi?.error({
          message: e.message
        });
      }
    }
  }, [id, name, mutateAsync, notificationApi]);
  const isVideoPreviewAvailable = showVideoPreview && !isTouchDevice && videoPreview?.location;
  return <div className={`w-full ${isVideoPreviewAvailable && 'glow-on-hover'} ${rootClassName}`} data-sentry-component="CharacterCard" data-sentry-source-file="CharacterCard.tsx">
      <div ref={ref} className="w-full aspect-character-image relative rounded-2xl sm:rounded-3xl overflow-hidden cursor-pointer group" onClick={handleOpenConversation}>
        <div className="absolute inset-0 z-[1]">
          <Image src={avatar?.location ?? ''} alt={name} className="object-cover z-[1]" fill sizes="(min-width: 768px) 320px, 360px" quality={90} onLoad={() => setIsImageLoading(false)} data-sentry-element="Image" data-sentry-source-file="CharacterCard.tsx" />
          {isImageLoading && <Skeleton.Node rootClassName="relative !w-full !h-full" className="!w-full !h-full" active />}
          {isVideoPreviewAvailable && videoPreview?.location && <VideoPreview hoverableRef={ref} videoSrc={videoPreview?.location} />}
        </div>
        <Button className="!absolute right-[10px] top-[10px] z-[2]" type="primary" shape="circle" icon={<ChatIcon className="!text-xl" />} size="large" loading={isPending} data-sentry-element="Button" data-sentry-source-file="CharacterCard.tsx" />

        <div className="absolute bottom-0 left-0 right-0 z-[2] flex flex-col justify-end p-3 sm:p-4 bg-gradient-to-t from-black/80 from-30%">
          <h1 className="font-bold text-[16px] leading-tight">{name}</h1>

          <p className="text-textBase-600 text-[12px] line-clamp-2 lg:group-hover:line-clamp-[8] max-h-10 lg:group-hover:max-h-40 duration-500">
            {description}
          </p>
        </div>
      </div>
    </div>;
};
export default CharacterCard;