import React, { JSX, ComponentType } from 'react';
interface Props {
  icon: JSX.Element;
  text?: string | JSX.Element;
  className?: string;
  textClassName?: string;
}
const EmptyStateCard: ComponentType<Props> = ({
  icon,
  text = '',
  className = '',
  textClassName = ''
}) => {
  return <div className={`flex flex-col items-center justify-center gap-2 sm:gap-3 text-center p-4 sm:p-6 w-full aspect-character-image border-2 border-dashed border-primary-300 rounded-2xl sm:rounded-3xl text-primary-300 ${className}`} data-sentry-component="EmptyStateCard" data-sentry-source-file="EmptyStateCard.tsx">
      <div className="text-3xl sm:text-4xl">{icon}</div>
      {text && <h1 className={`text-md sm:text-xl font-bold ${textClassName}`}>
          {text}
        </h1>}
    </div>;
};
export default EmptyStateCard;