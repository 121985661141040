import {
  TraitsUserInputModel,
  TraitPersonalityTypeEnum,
} from '@project-adonis/validators';
import { TraitGroupType } from '@/src/app/create-character/Form/FormData';
import HttpError from '@/src/shared/api/http-error';

export const swrMutationFetcherCreator = <TBody, TResponse>(
  method: 'POST' | 'GET' | 'PUT' | 'DELETE',
) => {
  return async (url: string, { arg }: { arg: TBody }): Promise<TResponse> => {
    const response = await fetch(url, {
      method: method,
      body: JSON.stringify(arg),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new HttpError({
        status: response.status,
        message: data.message,
        data: data,
      });
    }

    return data;
  };
};

export const extractNatualNumber = (decimal: number): number => {
  return Math.floor(decimal);
};

export const extractDecimalNumber = (decimal: number): number => {
  return Number(((decimal - extractNatualNumber(decimal)) * 100).toFixed(0));
};

export const convertStringToSlug = (string: string): string => {
  return string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const updateTraitGroupsWithDatabaseIds = (
  traitGroups: TraitGroupType[],
  databaseTraits: TraitsUserInputModel[],
): TraitGroupType[] => {
  return traitGroups.map((traitGroup) => {
    const updatedTraits = traitGroup.traits.map((trait) => {
      const matchingDatabaseTrait = databaseTraits.find(
        (dbTrait) =>
          dbTrait.name === trait.name &&
          dbTrait.type ===
            (traitGroup.key as unknown as TraitPersonalityTypeEnum),
      );
      if (matchingDatabaseTrait) {
        return { ...trait, id: matchingDatabaseTrait.id };
      }
      return trait;
    });
    return { ...traitGroup, traits: updatedTraits };
  });
};
