import { ComponentType, useEffect, useRef } from 'react';
interface Props {
  onLoadMore: () => void;
  visibility?: number;
}
const LoadMoreVisibilityTrigger: ComponentType<Props> = ({
  onLoadMore,
  visibility = 100
}) => {
  const triggerRef = useRef(null);
  useEffect(() => {
    const currentRef = triggerRef.current;
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        onLoadMore();
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: visibility / 100
    });
    if (currentRef) {
      observer.observe(currentRef);
    }
    return (): void => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [onLoadMore, visibility]);
  return <div ref={triggerRef} className="invisible h-px" data-sentry-component="LoadMoreVisibilityTrigger" data-sentry-source-file="LoadMoreVisibilityTrigger.tsx" />;
};
export default LoadMoreVisibilityTrigger;