'use client';

import { Button } from 'antd';
import { useTranslations } from 'next-intl';
import React, { ComponentType } from 'react';
interface Props {
  maxDiscount: number;
}
const SalesButton: ComponentType<Props> = ({
  maxDiscount
}) => {
  const t = useTranslations();
  return <div className="flex items-center gap-4" data-sentry-component="SalesButton" data-sentry-source-file="SalesButton.tsx">
      <div className="font-semibold text-lg max-md:hidden">
        {t.rich('salesCountdown.title', {
        span: children => <span className="inline-block px-2 py-1 bg-primary-700 font-black leading-none -rotate-2">
              {children}
            </span>,
        percentage: maxDiscount
      })}
      </div>
      <Button type="primary" data-sentry-element="Button" data-sentry-source-file="SalesButton.tsx">
        <span className="md:!hidden">
          {t('salesCountdown.button.mobile', {
          percentage: maxDiscount
        })}
        </span>
        <span className="max-md:!hidden">
          {t('salesCountdown.button.desktop')}
        </span>
      </Button>
    </div>;
};
export default SalesButton;